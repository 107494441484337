<template>
  <div
    v-if="filterFields.length"
    class="d-flex filter-container"
  >
    <div class="d-flex flex-row filter-selected-columns">
      <div
        v-for="(selectedColumn, index) in selectedColumns"
        :key="selectedColumn.field"
        class="selected-column h-100 d-flex align-items-center me-2"
      >
        <span class="selected-column_text">{{ selectedColumn.label }}</span>
        <kingpin-icon
          icon="XIcon"
          size="10px"
          class="cursor-pointer ms-2"
          @click="onRemoveFilter(selectedColumn.field, index)"
        />
      </div>
    </div>
    <div class="filter-dropdown">
      <b-dropdown
        ref="filter_dropdown"
        variant="none"
        toggle-class="filter-toggle"
        menu-class="filter-menu"
        no-flip
        no-caret
        @hide="preventHideDropdown"
      >
        <template #button-content>
          <div class="d-flex align-items-center">
            <kingpin-icon
              icon="PlusIcon"
              size="10px"
              class="m-1"
            />
            <span class="m-1 inter-400-16">FILTER</span>
          </div>
        </template>
        <b-dropdown-text>
          <form
            class="filter-dropdown-container"
            @submit.prevent="onApplyFilter"
          >
            <!-- Header with close icon -->
            <div class="d-flex inter-400-16 filter-dropdown-container_header">
              <div v-if="!(isColumnSelected)">
                SELECT COLUMN
              </div>
              <div class="ms-auto">
                <kingpin-icon
                  icon="XIcon"
                  size="12px"
                  class="cursor-pointer"
                  @click="onClickClose()"
                />
              </div>
            </div>
            <!-- Body of the filter -->
            <div class="filter-dropdown-container_body">
              <!-- Column list -->
              <column-list
                v-if="!isColumnSelected"
                :columns="getFilterColumns"
                @on-select-column="onSelectColumn"
              />
              <!-- String filter -->
              <string-filter
                v-if="isTypeString"
                :field-name="selectedColumnCurrent.field"
                :lookup-url="selectedColumnCurrent.lookupUrl"
                :extra-query-for-string-filter="extraQueryForStringFilter"
                @on-selected-values="onSelectedValues"
              />
              <!-- Date filter -->
              <date-filter
                v-if="isTypeDate"
                :field-name="selectedColumnCurrent.field"
                :field-label="selectedColumnCurrent.label"
                @on-selected-values="onSelectedValues"
              />
              <!-- Number filter -->
              <number-filter
                v-if="isTypeNumber"
                :field-name="selectedColumnCurrent.field"
                @on-selected-values="onSelectedValues"
              />
            </div>
            <!-- Footer with action buttons -->
            <div
              v-if="isColumnSelected"
              class="d-flex filter-dropdown-container_footer"
            >
              <k-button
                type="submit"
                class="ms-auto"
                variant="outline-medium"
                :disabled="!isValidFilter"
              >
                APPLY
              </k-button>
            </div>
          </form>
        </b-dropdown-text>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import { BDropdown, BDropdownText } from 'bootstrap-vue'
import { KingpinIcon, KButton } from '@kingpin-global/kingpin-ui'
import { FILTER_FIELD_TYPE } from '@/constants'
import ColumnList from './ColumnList.vue'
import StringFilter from './StringFilter.vue'
import NumberFilter from './NumberFilter.vue'
import DateFilter from './DateFilter.vue'

export default {
  name: 'MultipleFilter',
  components: {
    KingpinIcon,
    KButton,
    BDropdown,
    BDropdownText,
    ColumnList,
    StringFilter,
    NumberFilter,
    DateFilter,
  },
  props: {
    filterFields: {
      type: Array,
      default() {
        return []
      },
    },
    extraQueryForStringFilter: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      selectedColumnCurrent: this.getDefaultSelectedColumn,
      selectedColumns: [],
      FILTER_FIELD_TYPE,
      isValidFilter: false,
      fullPayload: {},
      shouldHideFilter: false,
    }
  },
  emits: ['on-apply-filter'],
  computed: {
    isColumnSelected() {
      return this.selectedColumnCurrent && this.selectedColumnCurrent.field
    },
    isTypeString() {
      return this.isColumnSelected && this.selectedColumnCurrent.type === FILTER_FIELD_TYPE.STRING
    },
    isTypeDate() {
      return this.isColumnSelected && this.selectedColumnCurrent.type === FILTER_FIELD_TYPE.DATE
    },
    isTypeNumber() {
      return this.isColumnSelected && this.selectedColumnCurrent.type === FILTER_FIELD_TYPE.NUMBER
    },
    getFilterColumns() {
      const selectedColumnKeys = this.selectedColumns.map(c => c?.field)
      return this.filterFields.filter(item => !selectedColumnKeys.includes(item?.field))
    },
  },
  methods: {
    getDefaultSelectedColumn() {
      return {
        field: '',
        type: '',
        lookupUrl: '',
      }
    },
    onSelectColumn(fieldObj) {
      const isSeletedAlready = this.selectedColumns.find(col => col.field === fieldObj.field)
      if (!isSeletedAlready) {
        this.selectedColumnCurrent = fieldObj
        this.selectedColumns.push(fieldObj)
      }
    },
    /// //// For filter dropdown ///////////
    preventHideDropdown(bvEvent) {
      if (this.shouldHideFilter) {
        this.shouldHideFilter = false
      } else {
        bvEvent.preventDefault()
      }
    },
    proceedHideDropdown() {
      this.shouldHideFilter = true
      this.$refs.filter_dropdown.hide()
    },
    /// /////////////////////////////////////
    onClickClose() {
      const fieldToRemove = this.selectedColumnCurrent.field
      this.selectedColumnCurrent = this.getDefaultSelectedColumn()
      this.selectedColumns = this.selectedColumns.filter(col => col.field !== fieldToRemove)
      this.proceedHideDropdown()
    },
    onRemoveFilter(field = '', index) {
      if (field) {
        if (index === (this.selectedColumns.length - 1)) {
          this.selectedColumnCurrent = this.getDefaultSelectedColumn()
        }
        this.selectedColumns = this.selectedColumns.filter(col => col.field !== field)
        if (this.fullPayload[field]) {
          delete this.fullPayload[field]
          this.$emit('on-apply-filter', this.fullPayload)
        }
      }
    },
    onApplyFilter() {
      this.$emit('on-apply-filter', this.fullPayload)
      this.selectedColumnCurrent = this.getDefaultSelectedColumn()
      this.isValidFilter = false
      this.proceedHideDropdown()
    },
    setFullPayload(data = {}) {
      this.fullPayload = {
        ...this.fullPayload,
        ...data,
      }
    },
    onSelectedValues({ isValidFilter, payload }) {
      this.isValidFilter = isValidFilter
      this.setFullPayload(payload)
    },
  },
}
</script>

<style lang="scss">
@import "./multiple-filter-styles/multiple-filter.scss";
</style>
