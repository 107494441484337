import {
  kpEndpoint, kpParams, kpRequest,
} from '@/axios'

export const FETCH_SHIPMENT_LIST = 'fetchShipmentList'
export const FETCH_SHIPMENTS = 'fetchShipments'
export const FETCH_SHIPMENT = 'fetchShipment'
export const SET_SHIPMENTS = 'setShipments'
export const SET_PAYLOAD = 'setPayload'
export const SET_SHIPMENT_COUNT = 'setShipmentCount'
export const SET_EDITING_SHIPMENT = 'setEditingShipment'
export const SET_EDITING_SHIPMENT_PROPERTY = 'setEditingShipmentProperty'
export const CREATE_PACKING_LIST = 'createPackingList'
export const UPDATE_PACKING_LIST = 'updatePackingList'
export const DELETE_PACKING_LIST = 'deletePackingList'
export const CLEAR_EDITING_SHIPMENT = 'clearEditingShipment'
export const SET_IS_PACKING_LIST_PRESENT = 'setIsPackingListPresent'
export const PATCH_SHIPMENT = 'patchShipment'

// Shipment Products Assigning and Unassigning
export const ASSIGN_OR_UNASSIGN_SHIPMENT_PRODUCTS = 'assignOrUnassignShipmentProducts'
export const SET_SHIPMENT_SEQ_ID = 'setShipmentSeqId'

export default {
  state: {
    shipmentList: [],
    shipmentCount: 0,
    editingShipment: {},
    isPackingListPresent: false,
    payload: {},
    shipmentSeqId: null,
  },
  actions: {
    [FETCH_SHIPMENT_LIST]({ commit, state }, { queryParams }) {
      return new Promise((resolve, reject) => {
        kpRequest({ ...kpEndpoint.shipment.getShipments, params: queryParams })
          .then(({ data }) => {
            if (state.payload === queryParams) {
              commit(SET_SHIPMENTS, data.data.shipments)
              commit(SET_SHIPMENT_COUNT, data.data.count)
            }
            resolve(data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    async [FETCH_SHIPMENT](context, shipmentId) {
      return await kpRequest({ ...kpParams(kpEndpoint.shipment.getShipmentDetails, shipmentId) })
    },
    async [PATCH_SHIPMENT](context, { shipmentId, payload }) {
      return await kpRequest({ ...kpParams(kpEndpoint.shipment.patch, shipmentId), payload })
    },
    async [DELETE_PACKING_LIST](context, { shipmentId, packingListId }) {
      return await kpRequest({ ...kpParams(kpEndpoint.shipment.packingList.delete, shipmentId, packingListId) })
    },
    async [UPDATE_PACKING_LIST](context, { shipmentId, packingListId, payload }) {
      return await kpRequest({ ...kpParams(kpEndpoint.shipment.packingList.update, shipmentId, packingListId), payload })
    },
    async [CREATE_PACKING_LIST](context, { shipmentId, payload }) {
      return await kpRequest({ ...kpParams(kpEndpoint.shipment.packingList.create, shipmentId), payload })
    },
    async [ASSIGN_OR_UNASSIGN_SHIPMENT_PRODUCTS](context, { shipmentId, payload, productUpdateType }) {
      const params = {
        productUpdateType,
      }
      return await kpRequest({ ...kpParams(kpEndpoint.shipment.assignOrUnassignShipment, shipmentId), payload, params })
    },
    async [FETCH_SHIPMENTS](context, params) {
      return await kpRequest({ ...kpEndpoint.shipment.getShipments, params })
    },
  },
  mutations: {
    [SET_PAYLOAD](state, payload) {
      state.payload = payload
    },
    [SET_SHIPMENTS](state, shipments) {
      state.shipmentList = shipments
    },
    [SET_SHIPMENT_COUNT](state, shipmentCount) {
      state.shipmentCount = shipmentCount
    },
    [SET_EDITING_SHIPMENT](state, shipment) {
      state.editingShipment = {
        ...shipment,
        shippedDate: shipment.shippedDate || '',
        deliveredDate: shipment.deliveredDate || '',
        currencyFromBrand: shipment.currencyFromBrand || '',
      }
    },
    [SET_EDITING_SHIPMENT_PROPERTY](state, { property, value }) {
      state.editingShipment = { ...state.editingShipment, [property]: value }
    },
    [SET_SHIPMENT_SEQ_ID](state, shipmentSeqId) {
      state.shipmentSeqId = shipmentSeqId
    },
    [CLEAR_EDITING_SHIPMENT](state) {
      state.shipmentSeqId = null
      state.editingShipment = {}
    },
    [SET_IS_PACKING_LIST_PRESENT](state, isPresent = false) {
      state.isPackingListPresent = isPresent
    },
  },
  getters: {
    shipmentList(state) {
      return state.shipmentList
    },
    shipmentCount(state) {
      return state.shipmentCount
    },
    getEditingShipment(state) {
      return state.editingShipment
    },
    shipmentSeqId(state) {
      return state.shipmentSeqId
    },
  },
}
