import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueTelInput from 'vue-tel-input'
// import VueCompositionAPI from '@vue/composition-api'
import vSelect from 'vue-select'
import { refreshTraceId } from '@/traceIdCookie'
import App from './App.vue'
import router from './router'
import store from './store'

// 3rd party plugins
import '@/libs/toastification'

// Import Custom scss with variables
import '@/assets/scss/style.scss'
import '@/@core/fonts/iconfont.css'

// Import scss from '@kingpin-global/kingpin-ui' package
import '@kingpin-global/kingpin-ui/src/assets/scss/component-styles.scss'

// Vue-tel-input css
import 'vue-tel-input/dist/vue-tel-input.css'

//  vue-select
import 'vue-select/dist/vue-select.css'

// Global Components
import './global-components'

refreshTraceId()

// use bootstrap-vue globally
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueTelInput)
Vue.component('v-select', vSelect)

// Composition API
// Vue.use(VueCompositionAPI)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
