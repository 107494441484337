import { kpEndpoint, kpRequest } from '@/axios'

export const FETCH_TRANSACTIONS = 'fetchtTransactions'

export default {
  actions: {
    async [FETCH_TRANSACTIONS](context, payload) {
      return await kpRequest({ ...kpEndpoint.transactions.get, params: payload })
    },
  },
}
