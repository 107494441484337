import auth from '@/auth'
import router from '@/router'
import { destroyToken, getToken, saveToken } from '@/store/jwt.service'
import { constants } from '@kingpin-global/kingpin-utils-frontend'
import { refreshTraceId } from '@/traceIdCookie'

const { ADMIN_ROLES } = constants

export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const PURGE_AUTH = 'logOut'
export const AUTH_ERROR = 'authError'
export const SET_AUTHENTICATED = 'setAuthenticated'
export const SET_ROLE = 'setRole'

export const getAuthUser = () => {
  const { firstName, lastName } = localStorage
  return { firstName, lastName }
}

const saveAuthUser = (firstName, lastName, role) => {
  localStorage.firstName = firstName
  localStorage.lastName = lastName
  localStorage.role = role
  refreshTraceId()
}
const state = {
  isAuthenticated: !!getToken(),
  authError: '',
  role: '',
}
const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      // TODO: Move this POST URl to axios
      auth
        .post('admin/noAuth/login', credentials)
        .then(({ data }) => {
          if (data.data.token) {
            saveToken(data.data.token)
            saveAuthUser(data.data.firstName, data.data.lastName, data.data.role)
            context.commit('setAuthenticated')
            context.commit(SET_ROLE)
            resolve(data)
          } else {
            context.commit(PURGE_AUTH)
            context.commit(AUTH_ERROR, data.message)
            reject(data.message)
          }
        })
        .catch(err => {
          const errMessage = err.response?.data.message || err.message
          context.commit(AUTH_ERROR, errMessage)
          reject(errMessage)
        })
    })
  },
  [LOGOUT]() {
    destroyToken()
    refreshTraceId()
    router.push({ path: '/login' })
  },
}
const mutations = {
  setAuthenticated(state) {
    state.isAuthenticated = true
  },
  [SET_ROLE](state) {
    const role = localStorage.getItem('role') || ''
    state.role = role
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false
    JwtService.destroyToken()
  },
  [AUTH_ERROR](state, error) {
    state.authError = error
  },
}
const getters = {
  isAuthenticated(state) {
    return state.isAuthenticated
  },
  isAdmin(state) {
    return state.role === ADMIN_ROLES.ADMIN
  },
  isSuperAdmin(state) {
    return state.role === ADMIN_ROLES.SUPERADMIN
  },
  role(state) {
    return state.role
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
