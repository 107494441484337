<template>
  <div>
    <label
      for="date_range_filter"
      class="m-1 kp-text-color"
    >{{ fieldLabel }}</label>
    <flat-pickr
      id="date_range_filter"
      ref="date_filter"
      :value="null"
      class="form-control bg-white custom-flatpickr-input"
      placeholder="Select date"
      :config="{ dateFormat: 'd M Y', mode: 'range' }"
      @on-change="onSelectDate"
    />
  </div>
</template>

<script>
import FlatPickr from 'vue-flatpickr-component'

export default {
  name: 'DateFilter',
  components: {
    FlatPickr,
  },
  props: {
    fieldName: {
      type: String,
      default() {
        return ''
      },
    },
    fieldLabel: {
      type: String,
      default() {
        return ''
      },
    },
  },
  date() {
    return {
      isValidFilter: false,
    }
  },
  emits: ['on-selected-values'],
  methods: {
    onSelectDate(selectedDates) {
      let payload = {}
      this.isValidFilter = false
      if (selectedDates && selectedDates.length) {
        const startDate = selectedDates[0]
          ? new Date(selectedDates[0]).valueOf()
          : null
        const endDate = selectedDates[1]
          ? new Date(selectedDates[1]).valueOf()
          : null
        payload = {
          [this.fieldName]: [startDate, startDate !== endDate ? endDate : null],
        }
        this.isValidFilter = true
      }
      this.$emit('on-selected-values', {
        payload,
        isValidFilter: this.isValidFilter,
      })
    },
  },
}
</script>

<style lang="scss">
@import "./multiple-filter-styles/date-filter.scss";
</style>
