import Cookies from 'js-cookie'

export function refreshTraceId() {
  const { firstName, lastName } = localStorage
  const traceId = `${firstName || '?'}-${lastName || '?'}-${(Math.random() + 1).toString(36).substring(2)}` // NOSONAR javascript:S2245
  document.cookie = `traceId=${traceId}; path=/; Secure; Domain=.kingpin.global; Max-Age=86400;`
  return traceId
}

export function getTraceId() {
  const traceId = Cookies.get('traceId')
  if (!traceId) {
    return traceId
  } else {
    return refreshTraceId()
  }
}
