<template>
  <div class="d-flex flex-column column-list">
    <div v-if="columns.length">
      <div
        v-for="column of columns"
        :key="column.field"
        class="mb-1 column-item cursor-pointer"
        @click.stop="$emit('on-select-column', column)"
      >
        <span class="kp-text-color inter-400-16 column-item_text">
          {{ column.label }}
        </span>
      </div>
    </div>
    <div
      v-else
      class="text-center text-primary"
    >
      There are no more columns to filter.
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColumnList',
  props: {
    columns: {
      type: Array,
      default() {
        return []
      },
    },
  },
  emits: ['on-select-column'],
}
</script>

<style lang="scss" scoped>
@import "./multiple-filter-styles/column-list.scss";
</style>
