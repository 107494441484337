<template>
  <div class="filter-string-continer">
    <div class="filter-search-bar-container">
      <k-form-group class="kp-text-color">
        <k-form-input
          class="filter-search-bar-input"
          type="text"
          placeholder="SEARCH"
          @input="onSearch"
        />
        <kingpin-icon
          icon="SearchIcon"
          size="18px"
          class="cursor-pointer input-append-icon-position"
        />
      </k-form-group>
    </div>
    <div
      :id="scrollContainerId"
      class="mt-2 filter-string-result-container"
    >
      <div
        v-for="(item, index) in stringResult"
        :key="`${item}${index}`"
        class="d-flex align-items-center cursor-pointer item"
        @click="selectItem(item)"
      >
        <div class="item_checkbox">
          <span>
            <label class="custom-checkbox-container">
              <input
                type="checkbox"
                :checked="selectedStrings.includes(item)"
                @input.stop
                @click="selectItem(item)"
              >
              <span class="checkmark" />
            </label>
          </span>
        </div>
        <div class="inter-400-16 kp-text-color item_value">
          {{ item }}
        </div>
      </div>
      <div
        v-if="isLoading"
        class="text-center my-2"
      >
        <b-spinner
          small
          variant="primary"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  KFormGroup,
  KFormInput,
  KingpinIcon,
} from '@kingpin-global/kingpin-ui'
import { BSpinner } from 'bootstrap-vue'
import _ from 'lodash'
import { apiToastError } from '@/@core/utils/toast'

export default {
  name: 'StringResult',
  components: {
    KFormGroup,
    KFormInput,
    KingpinIcon,
    BSpinner,
  },
  props: {
    fieldName: {
      type: String,
      default() {
        return ''
      },
    },
    lookupUrl: {
      type: String,
      default() {
        return ''
      },
    },
    extraQueryForStringFilter: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      stringResult: [],
      selectedStrings: [],
      scrollContainerId: 'filter_string_list',
      isLoading: false,
      // perPage: 10,
      // currentPage: 0,
      totalCount: 0,
      searchingText: '',
    }
  },
  emits: ['on-selected-values'],
  computed: {
    isLoadedFullResult() {
      return this.stringResult.length >= this.totalCount
    },
  },
  created() {
    if (this.lookupUrl) {
      this.initItems()
    }
  },
  mounted() {
    // COMMENT: Temporarly commented - will enable If implemented in backend
    // const listContainer = document.getElementById(this.scrollContainerId)
    // listContainer.addEventListener('scroll', () => {
    //   if (listContainer.scrollTop + listContainer.clientHeight >= listContainer.scrollHeight) {
    //     if (!this.isLoading && !this.isLoadedFullResult) {
    //       this.loadItemsMore()
    //     }
    //   }
    // })
  },
  unmounted() {
    // COMMENT: Temporarly commented - will enable If implemented in backend
    // const listContainer = document.getElementById(this.scrollContainerId)
    // listContainer.removeEventListener('scroll')
  },
  methods: {
    initItems() {
      this.fetchItems()
    },
    // loadItemsMore() {
    //   this.fetchItems()
    // },
    fetchItems() {
      const queryParams = { ...this.extraQueryForStringFilter }
      if (this.searchingText) {
        queryParams.query = this.searchingText
      }
      this.isLoading = true
      this.$kpApi.get(this.lookupUrl, { params: queryParams })
        .then(res => {
          const data = res.data?.data?.values
          this.stringResult = data
          this.totalCount = data?.length || 0
          this.isLoading = false
        })
        .catch(err => {
          apiToastError(err)
        })
    },
    selectItem(item) {
      const isAlreadySelected = this.selectedStrings.some(result => result === item)
      if (isAlreadySelected) {
        this.selectedStrings = this.selectedStrings.filter(s => s !== item)
      } else {
        this.selectedStrings.push(item)
      }
      const payload = {
        [this.fieldName]: this.selectedStrings,
      }
      this.$emit('on-selected-values', { payload, isValidFilter: !!this.selectedStrings.length })
    },
    onSearch: _.debounce(function onSearch(val) {
      this.searchingText = val
      this.stringResult = []
      this.initItems(val)
    }, 500),
  },
}
</script>

<style lang="scss">
@import "./multiple-filter-styles/string-filter.scss";
</style>
