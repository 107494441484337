import { constants, utils } from '@kingpin-global/kingpin-utils-frontend'
import { humanize, objectFlip, removePropFromObject } from './@core/utils/transformation/transformation'
import routesConstants from './routes-constants'

// Store constants
export const FETCH_RETAILER_USERS = 'fetchRetailerUsers'
export const FETCH_BRANDS_USERS = 'fetchBrandUsers'
export const RETAILER = 'retailer'
export const SET_RETAILER_USERS = 'setRetailerUsers'
export const SET_BRAND_USERS = 'setBrandUsers'
export const REMOVE_USERS = 'removeUsers'
export const ROLES = constants.ROLES

export const PAYMENT_TERMS = constants.PAYMENT_TERMS

export const LOGISTICS_TERMS = removePropFromObject(constants.LOGISTICS_TERMS, [constants.LOGISTICS_TERMS.FOB, constants.LOGISTICS_TERMS.DAP])

// order status
export const ORDER_STATUS = constants.ORDER_STATUS

export const ORDER_STATUSES = constants.ORDER_STATUS_LABELS

export const ADMIN_USER_STATUS = constants.ADMIN_USER_STATUS

// deal stages for order board view
export const ORDER_BOARD_VIEW_DEAL_STAGES = [
  { stage: ORDER_STATUS.NEW, label: 'Received' },
  { stage: ORDER_STATUS.ACCEPTED, label: 'Approved' },
  { stage: ORDER_STATUS.PENDING_RETAILER, label: 'Modified' },
  { stage: ORDER_STATUS.CONFIRMED, label: 'Confirmed' },
]

// Addresses Type
export const ADDRESSES_TYPE = constants.ADDRESSES_TYPE

// Transaction Status
export const TRANSACTION_STATUS = constants.TRANSACTION_STATUS

// Transcation Types
export const TRANSACTION_TYPES = constants.TRANSACTION_TYPES

// transaction role
export const TRANSACTION_ROLE = constants.TRANSACTION_ROLE

export const PAYMENT_METHODS = objectFlip(constants.PAYMENT_METHODS, humanize)

// shipment Status
export const SHIPMENT_STATUS = constants.SHIPMENT_TEXT

// Order Product Status
export const ORDER_PRODUCT_SHIPMENT_STATUS = constants.ORDER_PRODUCT_SHIPMENT_STATUS

// Order Product Status color
export const ORDER_PRODUCT_STATUS_COLOR_CODE = utils.ORDER_PRODUCT_STATUS_COLOR_CODE().ADMIN

export const segmentation = {
  wholesalePrices: [
    'Under $25',
    '$26 - $50',
    '$51 - $100',
    '$101 - $250',
    '$250+',
  ],
  retailPrices: [
    'Under $25',
    '$26 - $100',
    '$101 - $250',
    '$251 - $500',
    '$500+',
  ],
}

export const CURRENCY_CONVERSIONS = constants.CURRENCY_CONVERSIONS

export const ROUTES = routesConstants

export const FILE_TYPES = constants.FILE_TYPES

export const FILTER_FIELD_TYPE = {
  STRING: 'string',
  DATE: 'date',
  NUMBER: 'number',
}
export const API_METHODS = {
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  GET: 'get',
  DELETE: 'delete',
}
export const FILE_FORMATS = {
  CSV: 'csv',
  ZIP: 'zip',
  PDF: 'pdf',
  XLSX: 'xlsx',
}

export const USER_ROLES = {
  ENTITYADMIN: 'entityAdmin',
  SALESMANAGER: 'salesManager',
  SALESREPRESENTATIVE: 'salesRepresentative',
  KINGPINADMIN: 'kingpinAdmin',
}

export const USER_ROLES_LABEL = {
  [USER_ROLES.ENTITYADMIN]: 'Entity Admin',
  [USER_ROLES.SALESMANAGER]: 'Sales Manager',
  [USER_ROLES.SALESREPRESENTATIVE]: 'Sales Representative',
  [USER_ROLES.KINGPINADMIN]: 'Kingpin Admin',
}
