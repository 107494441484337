import { apiToastErrorV2 } from '@/@core/utils/toast'
import { constants } from '@kingpin-global/kingpin-utils-frontend'

const { CREDIT_DEBIT_NOTE_STATUS, CURRENCY_CONVERSIONS, NOTE_TYPES } = constants

const noteErrorMessage = {
  type: 'Please select a note type',
  date: 'Date issued field is mandatory',
  shipmentId: 'Reference Shipment Id field is mandatory',
  userId: 'Account field is mandatory',
  financials: 'Please fill mandatory fields in financials section',
}

export function getFinancialItem(currency) {
  return {
    currency,
    description: '',
    quantity: 1,
    amount: null, // Unit price
  }
}

export function getBuildDefaultNoteData() {
  const defaultCurrency = CURRENCY_CONVERSIONS.USD.LABEL
  return {
    status: CREDIT_DEBIT_NOTE_STATUS.APPROVED,
    type: NOTE_TYPES.CREDIT,
    currency: defaultCurrency,
    noteId: '',
    date: '',
    comments: '',
    shipmentId: '',
    userId: '',
    financials: [getFinancialItem(defaultCurrency)],
    attachments: [],
    // for display purpose
    shipmentSeqId: null,
    entityName: null,
    isVatEligible: false,
    vatPercentage: 0,
    includeAedEquivalent: false,
    generatedDocument: '',
    isAccess: false,
  }
}

export function getPayloadCreateNote(creatingNoteData) {
  const noteData = creatingNoteData
  const payload = {}
  for (const key in noteData) {
    switch (key) {
      case 'status':
      case 'comments':
      case 'vatPercentage':
        if (noteData[key]) {
          payload[key] = noteData[key]
        }
        break
      case 'attachments':
        if (noteData[key].length) {
          payload[key] = noteData[key]
        }
        break
      // Mandatory fields
      case 'date':
        if (!noteData[key]) {
          apiToastErrorV2(noteErrorMessage[key])
          return null
        }
        payload[key] = new Date(noteData[key]).valueOf()
        break
      case 'type':
      case 'shipmentId':
      case 'userId':
        if (noteData[key]) {
          payload[key] = noteData[key]
        } else {
          apiToastErrorV2(noteErrorMessage[key])
          return null
        }
        break
      case 'financials':
        if (noteData[key].length) {
          const filteredFinancials = noteData[key].filter(f => f.description && f.quantity && f.amount)
          if (!filteredFinancials.length) {
            apiToastErrorV2(noteErrorMessage[key])
            return null
          }
          payload[key] = filteredFinancials
        } else {
          apiToastErrorV2(noteErrorMessage[key])
          return null
        }
        break
      default:
        break
    }
  }
  return payload
}

export function getPayloadUpdateNote(updatedNote) {
  const noteData = updatedNote
  const payload = {}
  for (const key in noteData) {
    switch (key) {
      case 'comments':
        if (noteData[key]) {
          payload[key] = noteData[key]
        }
        break
      case 'attachments':
        if (noteData[key].length) {
          payload[key] = noteData[key]
        }
        break
      // Mandatory fields
      case 'date':
        if (!noteData[key]) {
          apiToastErrorV2(noteErrorMessage[key])
          return null
        }
        payload[key] = new Date(noteData[key]).valueOf()
        break
      default:
        break
    }
  }
  return payload
}
