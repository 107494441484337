export default {
  BRANDS: {
    name: 'brands',
    path: 'user/brands',
  },
  RETAILERS: {
    name: 'retailers',
    path: 'user/retailers',
  },
  COLLECTIONS: {
    name: 'collections',
    path: 'collections',
    CHILDREN: {
      ALL_COLLECTIONS: {
        name: 'all-collecions',
        path: 'all-collecions',
      },
      SCHEDULED: {
        name: 'scheduled',
        path: 'scheduled',
      },
    },
  },
  NOTES: {
    name: 'notes',
    path: 'notes',
    CHILDREN: {
      CREDIT: {
        name: 'credit',
        path: 'credit',
      },
      DEBIT: {
        name: 'debit',
        path: 'debit',
      },
    },
  },
  CREATE_NOTE: {
    name: 'create-note',
    path: 'create-note',
  },
  EDIT_NOTE: {
    name: 'edit-note',
    path: 'edit-note/:id',
  },
  APPLY_NOTE: {
    name: 'apply-note',
    path: 'apply-note/:id',
    CHILDREN: {
      BY_TRANSACTION: {
        name: 'by-transaction',
        path: 'by-transaction',
      },
      BY_NOTE: {
        name: 'by-note',
        path: 'by-note',
      },
    },
  },
  WALLET_RULES: {
    name: 'wallet-rules',
    path: 'wallet-rules',
    CHILDREN: {
      BRAND_RULES: {
        name: 'brand-rules',
        path: 'brand-rules',
      },
      RETAILER_RULES: {
        name: 'retailer-rules',
        path: 'retailer-rules',
      },
      BRAND_VS_RETAILER_RULES: {
        name: 'brand-vs-retailer-rules',
        path: 'brand-vs-retailer-rules',
      },
      PENDING_RULES: {
        name: 'pending-rules',
        path: 'pending-rules',
      },
    },
  },
  CREDIT_APPLICATION: {
    LIST: {
      name: 'credit-application-list',
      path: 'credit-application-list',
    },
    DETAIL: {
      name: 'credit-application-detail',
      path: 'credit-application-detail/:id',
    },
  },
  SHIPMENTS: {
    name: 'shipments',
    path: 'shipments',
  },
  SHIPMENT_MANAGEMENT: {
    name: 'shipment-managment',
    path: 'shipment-management/:shipmentId',
    CHILDREN: {
      SHIPMENT_PRODUCTS_TAB: {
        name: 'shipment-products-tab',
        path: 'shipment-products-tab',
      },
      SHIPMENT_DETAIL_PAGE: {
        name: 'shipment-details-tab',
        path: 'shipment-details-tab',
      },
    },
  },
  ORDERS: {
    name: 'orders',
    path: 'orders',
    CHILDREN: {
      LIST: {
        name: 'order-list',
        path: 'list',
      },
      BOARD: {
        name: 'order-board',
        path: 'board',
      },
    },
  },
  ORDER_MANAGEMENT: {
    name: 'order-managment',
    path: 'order-management/:orderId',
    CHILDREN: {
      ORDER_PRODUCTS_TAB: {
        name: 'order-products-tab',
        path: 'order-products-tab',
      },
      ORDER_DETAILS_TAB: {
        name: 'order-details-tab',
        path: 'order-details-tab',
      },
    },
  },
  ORDER_DETAIL: {
    name: 'orderdetails',
    path: 'order-details/:orderId',
  },
  TRANSACTIONS: {
    name: 'transactions',
    path: 'transactions',
    CHILDREN: {
      RECEIVABLES: {
        name: 'receivables',
        path: 'receivables',
      },
      PAYABLES: {
        name: 'payables',
        path: 'payables',
      },
    },
  },
  USERS: {
    USERS: {
      name: 'users',
      path: 'user/users',
    },
    BRANDS: {
      name: 'brands',
      path: 'user/brands',
    },
    RETAILERS: {
      name: 'retailers',
      path: 'user/retailers',
    },
    ADMIN_USERS: {
      name: 'admin-users',
      path: 'user/admin-users',
    },
    USER_DETAILS: {
      name: 'user-details',
      path: 'user-details/:id',
    },
  },
  REPORTS: {
    name: 'reports',
    path: 'reports',
  },
  LOGIN: {
    name: 'auth-login',
    path: '/login',
  },
  DASHBOARD: {
    name: 'dashboard',
    path: '',
  },
}
