import {
  kpEndpoint, kpParams,
  kpRequest,
} from '@/axios'
import { getBuildDefaultNoteData } from '@/views/notes/notes-utils'
import { isSuperAdmin } from '../helpers/getters'

export const FETCH_CREDIT_DEBIT_NOTES = 'fetchCreditDebitNotes'
export const CREATE_NOTE = 'createNote'
export const RESET_NOTE = 'resetNote'
export const GET_NOTE = 'getNote'
export const SET_NOTE = 'setNote'
export const UPDATE_NOTE = 'updateNote'
export const DELETE_NOTE_ATTACHMENT = 'deleteNoteAttachment'
export const GET_CN_TRANSACTIONS_BY_NOTE = 'getCNTransactionsByNote'
export const GET_CN_TRANSACTIONS_BY_TRANSACTION = 'getCNTransactionsByTransaction'
export const APPLY_NOTE = 'applyNote'

export default {
  state: {
    createOrEditNote: getBuildDefaultNoteData(),
  },
  actions: {
    async [FETCH_CREDIT_DEBIT_NOTES](context, params) {
      return await kpRequest({ ...kpEndpoint.creditAndDebit.list, params })
    },
    async [CREATE_NOTE](context, payload) {
      return await kpRequest({ ...kpEndpoint.creditAndDebit.create, payload })
    },
    async [GET_NOTE](context, id) {
      return await kpRequest({ ...kpParams(kpEndpoint.creditAndDebit.getById, id) })
    },
    async [UPDATE_NOTE](context, { id, payload }) {
      return await kpRequest({ ...kpParams(kpEndpoint.creditAndDebit.update, id), payload })
    },
    async [DELETE_NOTE_ATTACHMENT](context, { id, url }) {
      const params = {
        fileUrl: url,
      }
      return await kpRequest({ ...kpParams(kpEndpoint.creditAndDebit.deleteAttachment, id), params })
    },

    // FOR APPLY NOTE
    async [GET_CN_TRANSACTIONS_BY_NOTE](context, id) {
      return await kpRequest({ ...kpParams(kpEndpoint.creditAndDebit.getCNTransactionsByNoteId, id) })
    },
    async [GET_CN_TRANSACTIONS_BY_TRANSACTION](context, id) {
      return await kpRequest({ ...kpParams(kpEndpoint.creditAndDebit.getCNTransactionsByTransactionId, id) })
    },
    async [APPLY_NOTE](context, { id, payload }) {
      return await kpRequest({ ...kpParams(kpEndpoint.creditAndDebit.applyNote, id), payload })
    },
  },
  mutations: {
    [SET_NOTE](state, note) {
      state.createOrEditNote = getBuildDefaultNoteData()
      state.createOrEditNote = note
      state.createOrEditNote.isAccess = isSuperAdmin(state, store.getters)
    },
    [RESET_NOTE](state) {
      state.createOrEditNote = getBuildDefaultNoteData()
      state.createOrEditNote.isAccess = isSuperAdmin(state, store.getters)
    },
  },
}
