import { constants } from '@kingpin-global/kingpin-utils-frontend'
import { apiToastSuccessV2 } from './@core/utils/toast'

const { ORDER_PRODUCT_SHIPMENT_STATUS, SHIPMENT_TEXT } = constants

// Function for copy the text to clipboard
export const copyToClipboard = (text = '', message = 'Copied!') => {
  navigator.clipboard.writeText(text)
  apiToastSuccessV2(message)
}

// Download Pdf
export const downloadPDF = ({ pdf, fileName }) => {
  fileName = fileName || 'export.pdf'
  if (!pdf.match(/^data:application\/pdf/i)) {
    pdf = `data:application/pdf;base64,${pdf}`
  }
  download(pdf, fileName)
}

// download xlsx
export const downloadXlsx = ({ xlsx, fileName }) => {
  if (Array.isArray(xlsx)) {
    xlsx = xlsx.join('\n')
  }
  fileName = fileName || 'export.xlsx'
  if (!xlsx.match(/^data:application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet/i)) {
    xlsx = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${xlsx}`
  }
  const data = encodeURI(xlsx)
  download(data, fileName)
}
export const downloadCSV = ({ csv, fileName }) => {
  if (Array.isArray(csv)) {
    csv = csv.join('\n')
  }
  fileName = fileName || 'export.csv'
  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`
  }
  const data = encodeURI(csv)
  download(data, fileName)
}

export const downloadZip = ({ zip, fileName }) => {
  if (Array.isArray(zip)) {
    zip = zip.join('\n')
  }
  fileName = fileName || 'export.zip'
  if (!zip.match(/^data:application\/zip/i)) {
    zip = `data:application/zip;base64,${zip}`
  }
  download(zip, fileName)
}

// download
export const download = (data, fileName) => {
  const link = document.createElement('a')
  link.setAttribute('href', data)
  link.setAttribute('download', fileName)
  link.click()
}

export const getFileNameAndExtension = url => {
  const filenameWithExtension = url.substring(url.lastIndexOf('/') + 1)
  const splitedName = filenameWithExtension.split('.')
  const fileName = splitedName[0]
  const extension = splitedName[1]
  return { fileName, extension }
}

export const getBase64FromBuffer = (bufferArray = []) => btoa(String.fromCharCode(...new Uint8Array(bufferArray)))

export const isProductInShipment = status => {
  const SHIPPED = ORDER_PRODUCT_SHIPMENT_STATUS.SHIPPED.LABEL
  return [...Object.keys(SHIPMENT_TEXT), SHIPPED].includes(status)
}

export const convertToHtml = message => {
  let htmlMessage = message.replace('.', '.<br/>')
  htmlMessage = htmlMessage.replace('"', '<strong class=\'color-blue\'>')
  htmlMessage = htmlMessage.replace('"', '</strong>')
  return htmlMessage
}

export const toTitleCase = camelCase => {
  if (!camelCase) { return '' }
  const result = camelCase.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export const getFileName = (filename, link, extenstion = '') => {
  const fileDate = link.split('_')
  return `${filename}${fileDate[1] ? `_${fileDate[1].replaceAll('-', '/')}` : '' || `${extenstion}`}`
}

export const formatNumber = (price, decimal) => (price
  ? Number(price).toLocaleString('en-us', { minimumFractionDigits: decimal })
  : '')

export const textTruncate = (str = '') => {
  if (str.length >= 25) {
    return `${str.substr(0, 10)}...${str.substr(str.length - 10, str.length)}`
  }
  return str
}
