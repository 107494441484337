import { kpEndpoint, kpParams, kpRequest } from '@/axios'

export const FETCH_WALLET_RULES = 'fetchWalltetRules'
export const CREATE_WALLET_RULES = 'createhWalltetRules'
export const UPDATE_REFERRAL_WALLET_RULE_STATUS = 'updateReferralWalletRuleStatus'
export const UPDATE_WALLET_RULE = 'updateWalletRule'

export default {
  actions: {
    async [FETCH_WALLET_RULES](ctx, filterQuery = {}) {
      return await kpRequest({ ...kpEndpoint.wallet.get, params: filterQuery })
    },
    async [CREATE_WALLET_RULES](ctx, payload) {
      return await kpRequest({ ...kpEndpoint.wallet.post, payload })
    },
    async [UPDATE_REFERRAL_WALLET_RULE_STATUS](ctx, { referralId, payload }) {
      return await kpRequest({ ...kpParams(kpEndpoint.wallet.updateReferralWalletRuleStatus, referralId), payload })
    },
    async [UPDATE_WALLET_RULE](ctx, { walletRuleId, payload }) {
      return await kpRequest({ ...kpParams(kpEndpoint.wallet.patch, walletRuleId), payload })
    },
  },
}
