import { ROUTES } from '@/constants'
import views from './views'

export default [
  {
    path: ROUTES.LOGIN.path,
    name: ROUTES.LOGIN.name,
    component: () => import('../../views/landing/LandingPage.vue'),
    meta: {
      redirectIfLoggedIn: true,
    },
  },
  {
    path: ROUTES.DASHBOARD.path,
    name: ROUTES.DASHBOARD.name,
    component: () => import('../../views/dashboard/Dashboard.vue'),
    children: views,
  },
]
