<template>
  <div
    class="toastification-center"
    :class="`toastification-${variant}`"
  >
    {{ title }}
  </div>
</template>

<script>
export default {
  name: 'ToastificationMessage',
  props: {
    variant: {
      type: String,
      default: 'success',
    },
    title: {
      type: String,
      default: null,
    },
  },
}
</script>
