import { kpEndpoint, kpParams, kpRequest } from '@/axios'

export const FETCH_ADMIN_USERS = 'fetchAdminUsers'
export const CREATE_ADMIN_USER = 'createUser'
export const UPDATE_ADMIN_USER = 'updateUser'

export default {
  actions: {
    async [FETCH_ADMIN_USERS]() {
      return await kpRequest({ ...kpEndpoint.adminUser.get })
    },
    async [CREATE_ADMIN_USER](context, payload) {
      return await kpRequest({ ...kpEndpoint.adminUser.register, payload })
    },
    async [UPDATE_ADMIN_USER](context, { adminUserId, payload }) {
      return await kpRequest({ ...kpParams(kpEndpoint.adminUser.update, adminUserId), payload })
    },
  },
}
