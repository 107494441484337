import { isProductInShipment } from '@/common-utils'
import { ORDER_PRODUCT_SHIPMENT_STATUS } from '@/constants'

export const SET_IS_EXPANDED_ALL_PRODUCTS = 'setIsExpandedAllProducts'
export const SELECT_PRODUCT_TOGGLE = 'setProductSelectedToggle'
export const SET_ALL_PRODUCT_SELECTED = 'setAllProductSelected'
export const SET_ALL_PRODUCT_DESELECTED = 'setAllProductDeselected'
export const SELECT_READY_TO_SHIP_PRODUCTS = 'selectReadyToShipProducts'
export const SET_CURRENT_ORDER_PRODUCTS = 'setCurrentOrderProducts'
export const SET_SINGLE_SELECTED_PRODUCT = 'setSignleSelectedProduct'
export const SET_IS_SHIPMENT_PAGE = 'setIsShipmentPage'

const getReadyToshipProducts = products => {
  const readyToShipProducts = []
  products.forEach(product => {
    if (isReadyToShipProduct(product)) {
      readyToShipProducts.push(product.productId)
    }
  })
  return readyToShipProducts
}

const isProductsCanceledOrInShipment = (products, selectedProducts) => {
  let isAvailable = false
  selectedProducts.forEach(pId => {
    const res = products.find(product => (pId === product.productId) && (isProductInShipment(product.status) || isCancelledProduct(product)))
    if (res) { isAvailable = true }
  })
  return isAvailable
}

const isReadyToShipProduct = product => product.status === ORDER_PRODUCT_SHIPMENT_STATUS.READY_TO_SHIP.LABEL
const isCancelledProduct = product => product.status === ORDER_PRODUCT_SHIPMENT_STATUS.CANCELLED.LABEL

export default {
  // State
  state: {
    isExpandedAllProducts: false,
    orderProducts: [],
    selectedProducts: [],
    readyToShipProducts: [],
    isShipmentPage: false,
  },
  // Mutations
  mutations: {
    [SET_IS_SHIPMENT_PAGE](state, val) {
      state.isShipmentPage = val
    },
    [SET_IS_EXPANDED_ALL_PRODUCTS](state, val) {
      state.isExpandedAllProducts = val
    },
    [SET_CURRENT_ORDER_PRODUCTS](state, products) {
      state.orderProducts = products
    },
    [SET_SINGLE_SELECTED_PRODUCT](state, productId) {
      state.selectedProducts = [productId]
    },
    [SELECT_PRODUCT_TOGGLE](state, productData) {
      if (!(state.selectedProducts.includes(productData.productId))) {
        state.selectedProducts.push(productData.productId)
      } else {
        state.selectedProducts = state.selectedProducts.filter(product => !(product === productData.productId))
      }

      if (isReadyToShipProduct(productData) && !state.readyToShipProducts.includes(productData.productId)) {
        state.readyToShipProducts.push(productData.productId)
      } else {
        state.readyToShipProducts = state.readyToShipProducts.filter(product => !(product === productData.productId))
      }
    },
    [SET_ALL_PRODUCT_SELECTED](state, products) {
      state.selectedProducts = []
      state.readyToShipProducts = []
      if (state.isShipmentPage) {
        products.forEach(product => {
          state.selectedProducts.push(product.productId)
        })
      } else {
        products.forEach(product => {
          if (!isCancelledProduct(product) && !isProductInShipment(product.status)) {
            state.selectedProducts.push(product.productId)
          }
        })
      }

      state.readyToShipProducts = getReadyToshipProducts(products)
    },
    [SET_ALL_PRODUCT_DESELECTED](state) {
      state.selectedProducts = []
      state.readyToShipProducts = []
    },
    [SELECT_READY_TO_SHIP_PRODUCTS](state, products) {
      state.selectedProducts = []
      state.readyToShipProducts = []
      state.selectedProducts = getReadyToshipProducts(products)
      state.readyToShipProducts = getReadyToshipProducts(products)
    },
  },
  // Getters
  getters: {
    getIsExpandedAllProducts(state) {
      return state.isExpandedAllProducts
    },
    getOrderProducts(state) {
      return state.orderProducts
    },
    getSelectedProducts(state) {
      return state.selectedProducts
    },
    getSelectedProductsCount(state) {
      return state.selectedProducts.length
    },
    getReadyToshipProductsCount(state) {
      return state.readyToShipProducts.length
    },
    getIsProductsCanceledOrInShipment(state) {
      return isProductsCanceledOrInShipment(state.orderProducts, state.selectedProducts)
    },
    getIsShipmentPage(state) {
      return state.isShipmentPage
    },
  },
}
