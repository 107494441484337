import { kpEndpoint, kpParams, kpRequest } from '@/axios'

export const FETCH_CREDIT_APPLICATION_LIST = 'fetchCredtApplicationList'
export const GET_CREDIT_APPLICATION = 'getCredtApplication'
export const UPDATE_CREDIT_APPLICATION_STATUS = 'updateCredtApplicationStatus'

export default {
  actions: {
    // Getting all the credit application
    async [FETCH_CREDIT_APPLICATION_LIST](context, params) {
      return await kpRequest({ ...kpEndpoint.creditApplication.getcreditApplicationList, params })
    },
    async [UPDATE_CREDIT_APPLICATION_STATUS](context, { creditApplicationId, payload }) {
      return await kpRequest({ ...kpParams(kpEndpoint.creditApplication.updateStatus, creditApplicationId), payload })
    },
    async [GET_CREDIT_APPLICATION](context, creditApplicationId) {
      return await kpRequest({ ...kpParams(kpEndpoint.creditApplication.getcreditApplication, creditApplicationId) })
    },

  },
}
