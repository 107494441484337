/* eslint-disable import/no-cycle */
import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth.module'
import adminUsers from './modules/admin-user.module'
import users from './modules/user-module'
import transactions from './modules/transaction-module'
import sidebar from './modules/sidebar.module'
import orders from './modules/order.module'
import shipments from './modules/shipment.module'
import productModule from './modules/product.module'
import collections from './modules/collection.module'
import common from './modules/common.module'
import notes from './modules/notes.module'
import wallet from './modules/wallet.module'
import creditApplication from './modules/credit-application.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    adminUsers,
    users,
    transactions,
    sidebar,
    orders,
    shipments,
    productModule,
    collections,
    common,
    notes,
    wallet,
    creditApplication,
  },
})
