import axios from 'axios'

const auth = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

auth.interceptors.response.use(undefined, err => new Promise((() => {
  throw err
})))
export default auth
