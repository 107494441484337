import { destroyToken } from '@/store/jwt.service'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { ROUTES } from '@/constants'
import pages from './routes/pages'

// Routes
import { gotToOrderListPage, isUserLoggedIn } from '../auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: () => gotToOrderListPage(),
    },
    ...pages,
  ],
})

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = isUserLoggedIn()
  if (to.name !== ROUTES.LOGIN.name && !isAuthenticated) {
    destroyToken()
    next({ name: ROUTES.LOGIN.name })
  } else if (to.meta.redirectIfLoggedIn && isAuthenticated) {
    next({ name: 'dashboard' })
  } else {
    next()
  }
})

export default router
