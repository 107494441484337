<template>
  <div class="d-flex flex-column column-list">
    <div
      class="mb-1 column-item cursor-pointer"
      @click="onSelectCondition(NUMBER_FILTER_CONDITION.EQUAL)"
    >
      <span class="kp-text-color inter-400-16 column-item_text"> EQUAL </span>
    </div>
    <div
      v-if="selectedCondition === NUMBER_FILTER_CONDITION.EQUAL"
      class="mb-1"
    >
      <k-form-input
        v-model="numberFilterInput[NUMBER_FILTER_CONDITION.EQUAL]"
        class="number-filter-input"
        type="number"
        placeholder="EQUAL"
      />
    </div>
    <div
      class="mb-1 column-item cursor-pointer"
      @click="onSelectCondition(NUMBER_FILTER_CONDITION.GREATER_THAN)"
    >
      <span class="kp-text-color inter-400-16 column-item_text">
        GREATER THAN
      </span>
    </div>
    <div
      v-if="selectedCondition === NUMBER_FILTER_CONDITION.GREATER_THAN"
      class="mb-1"
    >
      <k-form-input
        v-model="numberFilterInput[NUMBER_FILTER_CONDITION.GREATER_THAN]"
        class="number-filter-input"
        type="number"
        placeholder="GREATER THAN"
      />
    </div>
    <div
      class="mb-1 column-item cursor-pointer"
      @click="onSelectCondition(NUMBER_FILTER_CONDITION.LESS_THAN)"
    >
      <span class="kp-text-color inter-400-16 column-item_text">
        LESS THAN
      </span>
    </div>
    <div
      v-if="selectedCondition === NUMBER_FILTER_CONDITION.LESS_THAN"
      class="mb-1"
    >
      <k-form-input
        v-model="numberFilterInput[NUMBER_FILTER_CONDITION.LESS_THAN]"
        class="number-filter-input"
        type="number"
        placeholder="LESS THAN"
      />
    </div>
    <div
      class="mb-1 column-item cursor-pointer"
      @click="onSelectCondition(NUMBER_FILTER_CONDITION.BETWEEN)"
    >
      <span class="kp-text-color inter-400-16 column-item_text"> BETWEEN </span>
    </div>
    <div
      v-if="selectedCondition === NUMBER_FILTER_CONDITION.BETWEEN"
      class="mb-1"
    >
      <k-form-input
        v-model="numberFilterInput[NUMBER_FILTER_CONDITION.BETWEEN].from"
        class="mb-1 number-filter-input"
        type="number"
        placeholder="FROM"
      />
      <k-form-input
        v-model="numberFilterInput[NUMBER_FILTER_CONDITION.BETWEEN].to"
        class="number-filter-input"
        type="number"
        placeholder="TO"
      />
    </div>
  </div>
</template>

<script>
import { KFormInput } from '@kingpin-global/kingpin-ui'

const NUMBER_FILTER_CONDITION = {
  EQUAL: 'eq',
  GREATER_THAN: 'gt',
  LESS_THAN: 'lt',
  BETWEEN: 'between',
}

export default {
  name: 'NumberFilter',
  components: {
    KFormInput,
  },
  props: {
    fieldName: {
      type: String,
      default() {
        return ''
      },
    },
  },
  data() {
    return {
      NUMBER_FILTER_CONDITION,
      selectedCondition: '',
      payload: {},
      // Dynamic input for number filter
      numberFilterInput: this.getInputFields,
      isValidFilter: false,
    }
  },
  computed: {
    getInputFields() {
      return {
        [NUMBER_FILTER_CONDITION.EQUAL]: null,
        [NUMBER_FILTER_CONDITION.GREATER_THAN]: null,
        [NUMBER_FILTER_CONDITION.LESS_THAN]: null,
        [NUMBER_FILTER_CONDITION.BETWEEN]: {
          from: null,
          to: null,
        },
      }
    },
  },
  watch: {
    numberFilterInput: {
      handler(val) {
        this.setPayload(val)
      },
      deep: true,
    },
  },
  emits: ['on-selected-values'],
  methods: {
    setDefaultValues() {
      this.numberFilterInput = this.getInputFields
    },
    onSelectCondition(condition = '') {
      if (condition) {
        this.setDefaultValues()
        if (this.selectedCondition === condition) {
          this.selectedCondition = ''
        } else {
          this.selectedCondition = condition
        }
      }
    },
    setPayload(data) {
      this.isValidFilter = false
      switch (this.selectedCondition) {
        case NUMBER_FILTER_CONDITION.EQUAL:
        case NUMBER_FILTER_CONDITION.GREATER_THAN:
        case NUMBER_FILTER_CONDITION.LESS_THAN:
          this.payload[this.fieldName] = {
            [this.selectedCondition]: parseFloat(data[this.selectedCondition]),
          }
          this.isValidFilter = !!data[this.selectedCondition]
          break
        case NUMBER_FILTER_CONDITION.BETWEEN:
          this.payload[this.fieldName] = {
            [this.selectedCondition]: [
              parseFloat(data[this.selectedCondition].from),
              parseFloat(data[this.selectedCondition].to),
            ],
          }
          this.isValidFilter = !!(data[this.selectedCondition]?.from && data[this.selectedCondition]?.to)
          break
        default:
          this.setDefaultValues()
          break
      }
      this.$emit('on-selected-values', { payload: this.payload, isValidFilter: this.isValidFilter })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./multiple-filter-styles/number-filter.scss";
</style>
