import { kpEndpoint, kpParams, kpRequest } from '@/axios'

export const GET_COLLECTION_LIST = 'getCollectionList'
export const UPDATE_COLLECTION = 'updateCollection'
export const GET_SCHEDULED_COLLECTION_LIST = 'getScheduledCollectionList'
export const GET_PRICE_BOOK = 'getPriceBook'

const actions = {
  async [GET_COLLECTION_LIST](context, params) {
    return await kpRequest({ ...kpEndpoint.collection.list, params })
  },
  async [GET_SCHEDULED_COLLECTION_LIST](context, params) {
    return await kpRequest({ ...kpEndpoint.collection.scheduledList, params })
  },
  async [UPDATE_COLLECTION](context, { collectionId, payload }) {
    return await kpRequest({ ...kpParams(kpEndpoint.collection.update, collectionId), payload })
  },

  async [GET_PRICE_BOOK](context, params) {
    return await kpRequest({ ...kpEndpoint.collection.priceBook.get, params })
  },
}

export default {
  actions,
}
