import { SET_ALL_PRODUCT_DESELECTED, SET_CURRENT_ORDER_PRODUCTS } from '../modules/product.module'

export const isSuperAdmin = (state, getters) => getters.userRole === 'superadmin'

export const commitSetCurrentOrderProducts = (commit, products) => {
  commit(SET_CURRENT_ORDER_PRODUCTS, products)
}

export const commitSetAllProductDeselected = commit => {
  commit(SET_ALL_PRODUCT_DESELECTED)
}
