export function humanize(str) {
  let i; const
    frags = str.split('_')
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1).toLowerCase()
  }
  return frags.join(' ')
}

export function objectFlip(obj, valueTransformFunc) {
  const ret = {}
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (valueTransformFunc) {
        ret[obj[key]] = valueTransformFunc(key)
      } else {
        ret[obj[key]] = key
      }
    }
  }
  return ret
}

export function removePropFromObject(obj, keys) {
  for (const key of keys) {
    if (key in obj) {
      delete obj[key]
    }
  }
  return obj
}
