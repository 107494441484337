import {
  kpEndpoint, kpParams, kpRequest,
} from '@/axios'
import {
  FETCH_BRANDS_USERS, FETCH_RETAILER_USERS, REMOVE_USERS, ROLES, SET_BRAND_USERS, SET_RETAILER_USERS,
} from '@/constants'

export const FETCH_USER = 'fetchUser'
export const FETCH_BRAND = 'fetchBrand'
export const FETCH_RETAILER = 'fetchRetailer'
export const UPDATE_BRAND = 'updateBrand'
export const UPDATE_RETAILER = 'updateRetailer'
export const UPDATE_USER = 'updateUserData'

export default {
  state: {
    retailers: [],
    brands: [],
  },
  actions: {
    [FETCH_RETAILER_USERS](context) {
      const queryParams = {
        role: ROLES.RETAILER,
      }
      kpRequest({ ...kpEndpoint.user.get, params: queryParams })
        .then(({ data }) => {
          const userList = data.data.map(item => ({
            name: item.entityName || '', // Some profiles may not have entity name so set that property with empty string
            email: item.email,
            id: item._id,
          }))
          context.commit(SET_RETAILER_USERS, userList)
        })
        .catch()
    },
    [FETCH_BRANDS_USERS](context) {
      const queryParams = {
        role: ROLES.BRAND,
      }
      kpRequest({ ...kpEndpoint.user.get, params: queryParams })
        .then(({ data }) => {
          const userList = data.data.map(item => ({
            name: item.entityName || '', // Some profiles may not have entity name so set that property with empty string
            email: item.email,
            id: item._id,
          }))
          context.commit(SET_BRAND_USERS, userList)
        })
        .catch()
    },
    async [FETCH_USER](ctx, id) {
      return await kpRequest({ ...kpParams(kpEndpoint.user.fetchUser, id) })
    },
    async [FETCH_BRAND](ctx, id) {
      return await kpRequest({ ...kpParams(kpEndpoint.brand.fetchUser, id) })
    },
    async [FETCH_RETAILER](ctx, id) {
      return await kpRequest({ ...kpParams(kpEndpoint.retailer.fetchUser, id) })
    },
    async [UPDATE_BRAND](ctx, { id, payload }) {
      return await kpRequest({ ...kpParams(kpEndpoint.brand.patchUser, id), payload })
    },
    async [UPDATE_RETAILER](ctx, { id, payload }) {
      return await kpRequest({ ...kpParams(kpEndpoint.retailer.patchUser, id), payload })
    },
    async [UPDATE_USER](ctx, { id, payload }) {
      return await kpRequest({ ...kpParams(kpEndpoint.user.patchUser, id), payload })
    },
  },
  mutations: {
    [SET_RETAILER_USERS](state, payload) {
      state.retailers = payload
    },
    [SET_BRAND_USERS](state, payload) {
      state.brands = payload
    },
    [REMOVE_USERS](state) {
      state.retailers = []
      state.brands = []
    },
  },
  getters: {
    retailers(state) {
      return state.retailers
    },
    brands(state) {
      return state.brands
    },
    getRetailerName: state => id => state.retailers.find(retailer => retailer.id === id).name,
    getBrandName: state => id => state.brands.find(brand => brand.id === id).name,
    retailerMap(state) {
      return state.retailers.reduce((obj, user) => {
        obj[user.id] = {
          email: user.email,
          entityName: user.name,
        }
        return obj
      }, {})
    },
    brandMap(state) {
      return state.brands.reduce((obj, user) => {
        obj[user.id] = {
          email: user.email,
          entityName: user.name,
        }
        return obj
      }, {})
    },
  },
}
