import store from '@/store'
import { getToken } from '@/store/jwt.service'
import { LOGOUT } from '@/store/modules/auth.module'
import axios from 'axios'
import Vue from 'vue'
import { API_METHODS } from './constants'

const {
  POST, PUT, PATCH, GET, DELETE,
} = API_METHODS

const kpApi = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_BACKEND_URL,
  headers: {
    lang: 'en',
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

kpApi.interceptors.request.use(
  config => {
    const token = getToken()
    if (token) config.headers.Authorization = `Bearer ${token}`
    return config
  },
  error => Promise.reject(error),
)

kpApi.interceptors.response.use(undefined, err => new Promise((() => {
  if (err.response && err.response.status !== 410) {
    // TODO: Uncomment this code after implement Vue Toaster
    // Vue.prototype.$toast.error(err.response.data.message, {
    //   position: 'top',
    // });
  }

  if (err.response.status === 401) {
    store.dispatch(LOGOUT)
  } else if (err.response.status === 410) {
    // TODO: Uncomment this code after implement Vue Toaster
    // Vue.prototype.$toast.error('There is issues in the order items', {
    //   position: 'top',
    // });
  }
  throw err
})))

Vue.prototype.$kpApi = kpApi

// Endpoints
const kpEndpoint = {
  adminUser: {
    get: { url: '/admin/adminusers', method: GET },
    register: { url: '/admin/adminusers/register', method: POST },
    update: { url: '/admin/adminusers/:id', method: PUT },
  },
  wallet: {
    get: { url: 'admin/walletRules', method: GET },
    post: { url: 'admin/walletRules', method: POST },
    patch: { url: 'admin/walletRules/:walletRuleId', method: PATCH },
    updateReferralWalletRuleStatus: { url: 'admin/users/referrals/:id', method: PATCH },
  },
  user: {
    get: { url: 'admin/users', method: GET },
    getUsers: { url: 'admin/users/user/list', method: GET },
    fetchUser: { url: 'admin/users/user/:userId', method: GET },
    patchUser: { url: 'admin/users/user/:userId', method: PATCH },
    export: { url: 'v2/users/user/export', method: GET },
  },
  brand: {
    get: { url: 'admin/users/brand', method: GET },
    getUsers: { url: 'admin/users/brand/list', method: GET },
    fetchUser: { url: 'admin/users/brand/:brand', method: GET },
    patchUser: { url: 'admin/users/brand/:brand', method: PATCH },
    export: { url: 'v2/users/brand/export', method: GET },
  },
  retailer: {
    get: { url: 'admin/users/retailer', method: GET },
    getUsers: { url: 'admin/users/retailer/list', method: GET },
    fetchUser: { url: 'admin/users/retailer/:retailer', method: GET },
    patchUser: { url: 'admin/users/retailer/:retailer', method: PATCH },
    export: { url: 'v2/users/retailer/export', method: GET },
  },
  transactions: {
    get: { url: '/admin/transaction', method: GET },
    patch: { url: '/admin/transaction/:transactionId', method: PATCH },
  },
  orders: {
    get: { url: 'orders', method: GET },
    getOrderDetails: { url: 'orders/:orderId', method: GET },
    patch: { url: 'orders/:orderId', method: PATCH },
    updateProductStatus: { url: 'orders/:orderId/products/status', method: PUT },
    modify: { url: 'orders/:orderId/products', method: PUT },
  },
  shipment: {
    createShipment: { url: 'shipments', method: POST },
    getShipments: { url: 'shipments', method: GET },
    getShipmentDetails: { url: 'shipments/:shipmentId', method: GET },
    assignOrUnassignShipment: { url: 'shipments/:shipmentId/products', method: PUT },
    patch: { url: 'shipments/:shipmentId', method: PATCH },
    packingList: {
      create: { url: 'shipments/:shipmentId/packing-list', method: POST },
      update: { url: 'shipments/:shipmentId/packing-list/:id', method: PUT },
      delete: { url: 'shipments/:shipmentId/packing-list/:id', method: DELETE },
    },
    downloadInvoice: { url: 'shipments/:id/download-invoice', method: GET },
    downloadDeliveryProof: { url: 'shipments/:id/download/delivery-proof', method: GET },
    downloadPackingList: { url: 'shipments/:shipmentId/download/packing-list', method: GET },
  },
  common: {
    commonApiGet: { url: '/common', method: GET },
    commonApiPost: { url: '/common', method: POST },
    auth: { url: '/common/auth', method: GET },
    fileUpload: { url: '/common/auth/files/:fileType', method: POST },
  },
  collection: {
    list: { url: 'v2/admin/catalog/collections', method: GET },
    scheduledList: { url: '/admin/users/scheduled-collections', method: GET },
    update: { url: 'v2/admin/catalog/collections/:collectionId', method: PUT },
    priceBook: {
      get: { url: 'v2/catalog/collections/price-books', method: GET },
    },
  },
  creditAndDebit: {
    list: { url: 'admin/creditAndDebits/notes', method: GET },
    create: { url: 'admin/creditAndDebits/notes', method: POST },
    getById: { url: 'admin/creditAndDebits/notes/:id', method: GET },
    update: { url: 'admin/creditAndDebits/notes/:id', method: PATCH },
    uploadAttachment: 'common/auth/files/credit-debit-attachment',
    deleteAttachment: { url: 'admin/creditAndDebits/notes/:id', method: DELETE },
    downloadInvoice: { url: 'admin/creditAndDebits/notes/:id/download-invoice', method: GET },
    getCNTransactionsByNoteId: { url: 'admin/creditAndDebits/notes/:id/transaction', method: GET },
    getCNTransactionsByTransactionId: { url: '/admin/creditAndDebits/notes/transaction/:id', method: GET },
    applyNote: { url: '/admin/creditAndDebits/notes/transaction/:id/apply-credit', method: PUT },
  },
  creditApplication: {
    getcreditApplicationList: { url: 'admin/creditAndDebits/credit-applications', method: GET },
    getcreditApplication: { url: 'admin/creditAndDebits/credit-application/:creditApplicationId', method: GET },
    updateStatus: { url: 'admin/creditAndDebits/credit-application/:creditApplicationId/status', method: PATCH },
  },
  reports: {
    marketPlaceOrdersMetrics: { url: 'metrics/sync', method: GET },
    marketPlaceOrdersAllMetrics: { url: 'metrics/sync?all=true', method: GET },
    directOrdersMetrics: { url: 'metrics/sync-direct', method: GET },
    investorMetrics: { url: 'metrics', method: GET },
  },
}

Vue.prototype.$kpEndpoint = kpEndpoint

const kpParams = (endpointObj = {}, ...params) => {
  let iteration = 0
  const urlWithParams = endpointObj?.url.replace(/:([a-zA-Z0-9]+)/g, (match, contents) => {
    if (params[iteration]) {
      contents = params[iteration]
      iteration++
    }
    return contents
  })
  return { url: urlWithParams, method: endpointObj?.method }
}
Vue.prototype.$kpParams = kpParams

const kpRequest = (obj = {}) => {
  const requestPayload = {
    ...obj,
    data: obj?.payload || {},
  }
  return kpApi(requestPayload)
}

Vue.prototype.$kpRequest = kpRequest

export {
  kpApi, kpEndpoint, kpParams, kpRequest,
}
