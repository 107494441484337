export const SET_TITLE = 'setTitle'
export const SET_SUB_TITLE = 'setSubTitle'
export const SET_COMPONENT = 'setComponent'
export const TOGGLE_SIDEBAR = 'toggleSidebar'
export const SET_FORM_ACTIONS = 'setFormActions'
export const SET_COMPONENT_CONTENT = 'setComponentContent'
export const SET_SIDEBAR_IS_ALWAYS_OPEN = 'setIsAlwaysOpen'
export const CLEAR_STATE = 'clearState'

export default {
  state: {
    title: '',
    subTitle: '',
    component: null,
    componentContent: null,
    display: false,
    isAlwaysOpen: false,
    formActions: {},
  },
  actions: {

  },
  mutations: {
    [SET_TITLE](state, payload) {
      state.title = payload
    },
    [SET_SUB_TITLE](state, payload) {
      state.subTitle = payload
    },
    [TOGGLE_SIDEBAR](state) {
      state.display = !state.display
    },
    [SET_COMPONENT](state, payload) {
      state.component = payload
    },
    [SET_SIDEBAR_IS_ALWAYS_OPEN](state, isAlwaysOpen) {
      state.isAlwaysOpen = isAlwaysOpen
    },
    [SET_COMPONENT_CONTENT](state, payload) {
      state.componentContent = payload
    },
    [SET_FORM_ACTIONS](state, payload) {
      state.formActions = payload
    },
    [CLEAR_STATE](state) {
      state.title = ''
      state.subTitle = ''
      state.component = null
      state.componentContent = null
      state.display = false
      state.isAlwaysOpen = false
      state.formActions = {}
    },
  },
}
