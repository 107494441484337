import { ROUTES } from '@/constants'

export default [
  {
    path: ROUTES.USERS.ADMIN_USERS.path,
    name: ROUTES.USERS.ADMIN_USERS.name,
    component: () => import('../../views/admin-users/UserList.vue'),
  },
  {
    path: ROUTES.USERS.USERS.path,
    name: ROUTES.USERS.USERS.name,
    component: () => import('../../views/users/UsersList.vue'),
  },
  {
    path: ROUTES.USERS.BRANDS.path,
    name: ROUTES.USERS.BRANDS.name,
    component: () => import('../../views/users/brand/BrandList.vue'),
  },
  {
    path: ROUTES.USERS.RETAILERS.path,
    name: ROUTES.USERS.RETAILERS.name,
    component: () => import('../../views/users/retailer/RetailerList.vue'),
  },
  {
    path: ROUTES.USERS.USER_DETAILS.path,
    name: ROUTES.USERS.USER_DETAILS.name,
    component: () => import('../../views/users/user-details/UserDetails.vue'),
  },
  {
    path: ROUTES.TRANSACTIONS.path,
    name: ROUTES.TRANSACTIONS.name,
    component: () => import('../../views/transaction/Transactions.vue'),
    children: [
      {
        path: ROUTES.TRANSACTIONS.CHILDREN.RECEIVABLES.path,
        name: ROUTES.TRANSACTIONS.CHILDREN.RECEIVABLES.name,
        component: () => import('../../views/transaction/Receivables.vue'),
      },
      {
        path: ROUTES.TRANSACTIONS.CHILDREN.PAYABLES.path,
        name: ROUTES.TRANSACTIONS.CHILDREN.PAYABLES.name,
        component: () => import('../../views/transaction/Payables.vue'),
      },
    ],
  },
  {
    path: ROUTES.ORDERS.path,
    name: ROUTES.ORDERS.name,
    component: () => import('../../views/order/Orders.vue'),
    children: [
      {
        path: '',
        redirect: { name: ROUTES.ORDERS.CHILDREN.LIST.name },
      },
      {
        path: ROUTES.ORDERS.CHILDREN.LIST.path,
        name: ROUTES.ORDERS.CHILDREN.LIST.name,
        component: () => import('../../views/order/OrderList.vue'),
      },
      {
        path: ROUTES.ORDERS.CHILDREN.BOARD.path,
        name: ROUTES.ORDERS.CHILDREN.BOARD.name,
        component: () => import('../../views/order/OrderBoard.vue'),
      },
    ],
  },
  {
    path: ROUTES.ORDER_DETAIL.path,
    name: ROUTES.ORDER_DETAIL.name,
    component: () => import('../../views/orderdetails/orderDetails.vue'),
  },
  {
    path: ROUTES.ORDER_MANAGEMENT.path,
    name: ROUTES.ORDER_MANAGEMENT.name,
    component: () => import('../../views/order-management/OrderManagement.vue'),
    children: [
      {
        path: '',
        redirect: { name: ROUTES.ORDER_MANAGEMENT.CHILDREN.ORDER_PRODUCTS_TAB.name },
      },
      {
        path: ROUTES.ORDER_MANAGEMENT.CHILDREN.ORDER_PRODUCTS_TAB.path,
        name: ROUTES.ORDER_MANAGEMENT.CHILDREN.ORDER_PRODUCTS_TAB.name,
        component: () => import('../../views/order-management/OrderProductsTab.vue'),
      },
      {
        path: ROUTES.ORDER_MANAGEMENT.CHILDREN.ORDER_DETAILS_TAB.path,
        name: ROUTES.ORDER_MANAGEMENT.CHILDREN.ORDER_DETAILS_TAB.name,
        component: () => import('../../views/order-management/OrderDetailsTab.vue'),
      },
    ],
  },
  {
    path: ROUTES.WALLET_RULES.name,
    name: ROUTES.WALLET_RULES.path,
    component: () => import('../../views/wallet/WalletRules.vue'),
    children: [
      {
        path: '',
        redirect: { name: ROUTES.WALLET_RULES.CHILDREN.BRAND_VS_RETAILER_RULES.name },
      },
      {
        path: ROUTES.WALLET_RULES.CHILDREN.BRAND_RULES.name,
        name: ROUTES.WALLET_RULES.CHILDREN.BRAND_RULES.path,
        component: () => import('../../views/wallet/BrandTerms.vue'),
      },
      {
        path: ROUTES.WALLET_RULES.CHILDREN.RETAILER_RULES.name,
        name: ROUTES.WALLET_RULES.CHILDREN.RETAILER_RULES.path,
        component: () => import('../../views/wallet/RetailerTerms.vue'),
      },
      {
        path: ROUTES.WALLET_RULES.CHILDREN.BRAND_VS_RETAILER_RULES.name,
        name: ROUTES.WALLET_RULES.CHILDREN.BRAND_VS_RETAILER_RULES.path,
        component: () => import('../../views/wallet/BrandVsRetailerTerms.vue'),
      },
      {
        path: ROUTES.WALLET_RULES.CHILDREN.PENDING_RULES.name,
        name: ROUTES.WALLET_RULES.CHILDREN.PENDING_RULES.path,
        component: () => import('../../views/wallet/PendingTerms.vue'),
      },
    ],
  },
  {
    path: ROUTES.SHIPMENTS.path,
    name: ROUTES.SHIPMENTS.name,
    component: () => import('../../views/shipment/Shipments.vue'),
  },
  {
    path: ROUTES.SHIPMENT_MANAGEMENT.path,
    name: ROUTES.SHIPMENT_MANAGEMENT.name,
    component: () => import('../../views/shipment-management/ShipmentManagement.vue'),
    children: [
      {
        path: '',
        redirect: { name: 'shipment-products-tab' },
      },
      {
        path: ROUTES.SHIPMENT_MANAGEMENT.CHILDREN.SHIPMENT_PRODUCTS_TAB.path,
        name: ROUTES.SHIPMENT_MANAGEMENT.CHILDREN.SHIPMENT_PRODUCTS_TAB.name,
        component: () => import('../../views/shipment-management/ShipmentProductsTab.vue'),
      },
      {
        path: ROUTES.SHIPMENT_MANAGEMENT.CHILDREN.SHIPMENT_DETAIL_PAGE.path,
        name: ROUTES.SHIPMENT_MANAGEMENT.CHILDREN.SHIPMENT_DETAIL_PAGE.name,
        component: () => import('../../views/shipment-management/ShipmentDetailsTab.vue'),
      },
    ],
  },
  {
    path: ROUTES.COLLECTIONS.path,
    name: ROUTES.COLLECTIONS.name,
    component: () => import('../../views/collections/Collections.vue'),
    children: [
      {
        path: '',
        redirect: { name: ROUTES.COLLECTIONS.CHILDREN.ALL_COLLECTIONS.name },
      },
      {
        path: ROUTES.COLLECTIONS.CHILDREN.ALL_COLLECTIONS.path,
        name: ROUTES.COLLECTIONS.CHILDREN.ALL_COLLECTIONS.name,
        component: () => import('../../views/collections/AllCollections.vue'),
      },
      {
        path: ROUTES.COLLECTIONS.CHILDREN.SCHEDULED.path,
        name: ROUTES.COLLECTIONS.CHILDREN.SCHEDULED.name,
        component: () => import('../../views/collections/ScheduledCollections.vue'),
      },
    ],
  },
  {
    path: ROUTES.NOTES.name,
    name: ROUTES.NOTES.path,
    component: () => import('../../views/notes/Notes.vue'),
    children: [
      {
        path: '',
        redirect: { name: ROUTES.NOTES.CHILDREN.CREDIT.name },
      },
      {
        path: ROUTES.NOTES.CHILDREN.CREDIT.name,
        name: ROUTES.NOTES.CHILDREN.CREDIT.path,
        component: () => import('../../views/notes/CreditNotes.vue'),
      },
      {
        path: ROUTES.NOTES.CHILDREN.DEBIT.name,
        name: ROUTES.NOTES.CHILDREN.DEBIT.path,
        component: () => import('../../views/notes/DebitNotes.vue'),
      },
    ],
  },
  {
    path: ROUTES.CREATE_NOTE.path,
    name: ROUTES.CREATE_NOTE.name,
    component: () => import('../../views/notes/create-note/CreateNote.vue'),
  },
  {
    path: ROUTES.EDIT_NOTE.path,
    name: ROUTES.EDIT_NOTE.name,
    component: () => import('../../views/notes/create-note/CreateNote.vue'),
  },
  {
    path: ROUTES.APPLY_NOTE.path,
    name: ROUTES.APPLY_NOTE.name,
    component: () => import('../../views/notes/apply-note/ApplyNote.vue'),
    children: [
      {
        path: '',
        redirect: { name: ROUTES.APPLY_NOTE.CHILDREN.BY_NOTE.name },
      },
      {
        path: ROUTES.APPLY_NOTE.CHILDREN.BY_TRANSACTION.name,
        name: ROUTES.APPLY_NOTE.CHILDREN.BY_TRANSACTION.path,
        component: () => import('../../views/notes/apply-note/ApplyNoteByTransaction.vue'),
      },
      {
        path: ROUTES.APPLY_NOTE.CHILDREN.BY_NOTE.name,
        name: ROUTES.APPLY_NOTE.CHILDREN.BY_NOTE.path,
        component: () => import('../../views/notes/apply-note/ApplyNoteByNote.vue'),
      },
    ],
  },
  {
    path: ROUTES.CREDIT_APPLICATION.LIST.name,
    name: ROUTES.CREDIT_APPLICATION.LIST.path,
    component: () => import('../../views/credit-application/CreditApplicationList.vue'),
  },
  {
    path: ROUTES.CREDIT_APPLICATION.DETAIL.path,
    name: ROUTES.CREDIT_APPLICATION.DETAIL.name,
    component: () => import('../../views/credit-application/CreditApplicationDetail.vue'),
  },
  {
    path: ROUTES.REPORTS.path,
    name: ROUTES.REPORTS.name,
    component: () => import('../../views/reports/Reports.vue'),
  },
]
