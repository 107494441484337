import { apiToastErrorV2, apiToastSuccessV2 } from '@/@core/utils/toast'
import {
  kpEndpoint, kpParams, kpRequest,
} from '@/axios'
import {
  downloadCSV, downloadPDF, downloadXlsx, downloadZip,
} from '@/common-utils'
import { FILE_FORMATS } from '@/constants'

export const FETCH_COMMON = 'fetchCommon'
export const COMMON_POST = 'commonPost'
export const UPLOAD_DOCUMENTS = 'uploadDocuments'
export const DOWNLOAD_FILE = 'downloadFile'

const state = {
  countries: [],
  departments: [],
}

const actions = {
  [FETCH_COMMON](ctx) {
    return new Promise((resolve, reject) => {
      kpRequest({ ...kpEndpoint.common.commonApiGet }).then(res => {
        ctx.state.countries = res.data.data.COUNTRIES
        ctx.state.departments = res.data.data.DEPARTMENTS
        resolve(res.data.data)
      }).catch(err => reject(err))
    })
  },
  async [COMMON_POST](ctx, payload = {}) {
    return await kpRequest({ ...kpEndpoint.common.commonApiPost, payload })
  },
  [UPLOAD_DOCUMENTS](ctx, { fileType, payload }) {
    return new Promise((resolve, reject) => {
      kpRequest({
        ...kpParams(kpEndpoint.common.fileUpload, fileType),
        payload,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(res => {
          resolve(res.data.data?.location)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  [DOWNLOAD_FILE](
    ctx,
    {
      url = '',
      params = {},
      fileName = '',
      successMessage = '',
      fileFormat = null,
      headers = {},
    },
  ) {
    return new Promise((resolve, reject) => {
      kpRequest({ ...url, params, ...headers })
        .then(res => {
          switch (fileFormat) {
            case FILE_FORMATS.CSV:
              downloadCSV({
                csv: res.data,
                fileName,
              })
              break
            case FILE_FORMATS.XLSX:
              downloadXlsx({
                xlsx: res?.data?.data?.fileStream || res.data.data,
                fileName,
              })
              break
            case FILE_FORMATS.PDF: {
              const reader = new FileReader()
              reader.readAsDataURL(res.data)
              reader.onloadend = () => {
                downloadPDF({
                  pdf: reader.result,
                  fileName,
                })
              }
              break
            }
            case FILE_FORMATS.ZIP:
              downloadZip({
                zip: res.data,
                fileName,
              })
              break
            default:
              break
          }
          if (successMessage) {
            apiToastSuccessV2(successMessage || 'Downloaded successfully')
          }
          resolve(res)
        })
        .catch(err => {
          apiToastErrorV2(err)
          reject(err)
        })
    })
  },
}

export default {
  state,
  actions,
}
