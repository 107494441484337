import {
  kpEndpoint, kpParams, kpRequest,
} from '@/axios'
import {
  commitSetCurrentOrderProducts,
  commitSetAllProductDeselected,
} from '../helpers/getters'

export const FETCH_ORDERS = 'fetchOrders'
export const FETCH_ORDER_BY_ID = 'fetchOrderById'
export const SET_ORDERS = 'setOrders'
export const SET_PAYLOAD = 'setPayload'
export const SET_ORDER_COUNT = 'setOrderCount'
export const SET_EDITING_ORDER = 'setEditingOrder'
export const SET_EDITING_ORDER_BILLING_ADDRESS_FOR_BRAND = 'setEditingOrderBillingAddressForBrand'
export const SET_EDITING_ORDER_BILLING_ADDRESS_FOR_RETAILER = 'setEditingOrderBillingAddressForRetailer'
export const SET_EDITING_ORDER_SHIPPING_ADDRESS_FOR_BRAND = 'setEditingOrderShippingAddressForBrand'
export const SET_EDITING_ORDER_SHIPPING_ADDRESS_FOR_RETAILER = 'setEditingOrderShippingAddressForRetailer'
export const SET_EDITING_ORDER_PROPERTY = 'setEditingOrderProperty'
export const UPDATE_ORDER_PRODUCT_STATUS = 'updateOrderProductStatus'
export const UPDATE_ORDER_PRODUCTS = 'updateOrderProducts'

export default {
  state: {
    orderList: [],
    orderCount: 0,
    editingOrder: {},
    payload: {},
  },
  actions: {
    [FETCH_ORDERS]({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        kpRequest({ ...kpEndpoint.orders.get, params: payload })
          .then(({ data }) => {
            if (state.payload === payload) {
              commit(SET_ORDERS, data.data.orders)
              commit(SET_ORDER_COUNT, data.data.count)
            }
            resolve(data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    [FETCH_ORDER_BY_ID](context, orderId) {
      return new Promise((resolve, reject) => {
        kpRequest({ ...kpParams(kpEndpoint.orders.getOrderDetails, orderId) })
          .then(res => {
            context.commit(SET_EDITING_ORDER, res.data.data)
            commitSetCurrentOrderProducts(context.commit, res.data.data.products)
            resolve(res.data.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    [UPDATE_ORDER_PRODUCT_STATUS](context, payload) {
      const statusPayload = {
        productIds: payload.productIds,
        status: payload.status,
      }
      return new Promise((resolve, reject) => {
        kpRequest({ ...kpParams(kpEndpoint.orders.updateProductStatus, payload.orderId), payload: statusPayload })
          .then(res => {
            resolve(res)
            commitSetAllProductDeselected(context.commit)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    [UPDATE_ORDER_PRODUCTS](context, { orderId, payload }) {
      return new Promise((resolve, reject) => {
        kpRequest({ ...kpParams(kpEndpoint.orders.modify, orderId), payload })
          .then(res => {
            resolve(res)
          }).catch(err => {
            reject(err)
          })
      })
    },
  },
  mutations: {
    [SET_PAYLOAD](state, payload) {
      state.payload = payload
    },
    [SET_ORDERS](state, orders) {
      state.orderList = orders
    },
    [SET_ORDER_COUNT](state, orderCount) {
      state.orderCount = orderCount
    },
    [SET_EDITING_ORDER](state, order) {
      state.editingOrder = {
        ...order,
        deliveryDate: order.deliveryDate || '',
      }
    },
    [SET_EDITING_ORDER_BILLING_ADDRESS_FOR_BRAND](state, billingAddress) {
      state.editingOrder = { ...state.editingOrder, billingAddressForBrand: billingAddress }
    },
    [SET_EDITING_ORDER_BILLING_ADDRESS_FOR_RETAILER](state, billingAddress) {
      state.editingOrder = { ...state.editingOrder, billingAddressForRetailer: billingAddress }
    },
    [SET_EDITING_ORDER_SHIPPING_ADDRESS_FOR_BRAND](state, shippingAddressData) {
      state.editingOrder = { ...state.editingOrder, shippingAddressForBrand: shippingAddressData }
    },
    [SET_EDITING_ORDER_SHIPPING_ADDRESS_FOR_RETAILER](state, shippingAddressData) {
      state.editingOrder = { ...state.editingOrder, shippingAddressForRetailer: shippingAddressData }
    },
    [SET_EDITING_ORDER_PROPERTY](state, { property, value }) {
      state.editingOrder = { ...state.editingOrder, [property]: value }
    },
  },
  getters: {
    orderList(state) {
      return state.orderList
    },
    orderCount(state) {
      return state.orderCount
    },
    getEditingOrder(state) {
      return state.editingOrder
    },
    orderMap(state) {
      return state.orderList.reduce((obj, order) => {
        obj[order._id] = {
          brandId: order.brandId,
          retailerId: order.retailerId,
          brandName: order.brandName,
          retailerName: order.retailerName,
        }
        return obj
      }, {})
    },
  },
}
