const ID_TOKEN_KEY = 'cTok'
export const getToken = () => window.localStorage.getItem(ID_TOKEN_KEY)
export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token)
}
export const destroyToken = () => {
  window.localStorage.clear()
}

export default {
  getToken,
  saveToken,
  destroyToken,
}
